<template>
    <div class="sim-cart-total">
        <!--  DISCOUNTS  -->
        <template v-if="type !== 'drawer' && cart?.discounts?.length! > 0">
            <div v-for="discount in cart?.discounts" :key="discount.id!" class="sim-text-normal flex items-center justify-between">
                <div>
                    {{ discount.name }} ({{ discount.code }})
                </div>

                <CoreIconLoadingSpinner v-if="isShippingMethodLoading || isPaymentMethodLoading" extra-small />
                <div>
                    {{
                    // TODO: fix
                    // @ts-ignore
                        getFormattedPrice(discount.taxed_amount)
                    }}
                </div>
            </div>
        </template>

        <!--  TOTAL PRICE  -->
        <div :class="['sim-cart-total__total', { 'sim-cart-total__total--subtitle': fontSize && fontSize === 'subtitle' } ]">
            <div>
                <span class="sim-font-bold block">
                    {{ $t('cart.total') }}
                </span>
                <span v-if="type === 'drawer'" class="sim-text-small sim-font-regular mt-1">
                    {{ $t('cart.including_vat') }}
                </span>
            </div>

            <CoreIconLoadingSpinner v-if="isShippingMethodLoading || isPaymentMethodLoading" extra-small />
            <div v-else class="sim-font-bold">
                {{ totalPrice }}
            </div>
        </div>
    </div>
</template>

<script lang="ts" setup>

const {
    fontSize = 'normal',
    type,
} = defineProps<{
    fontSize?: 'normal' | 'subtitle'
    type: 'cart-list' | 'summary' | 'drawer'
}>()

const { cart, isShippingMethodLoading, isPaymentMethodLoading } = useCart()

const totalPrice = computed(() => {
    switch (type) {
        case 'cart-list': return getFormattedPrice(cart.value?.discountedTaxedItemsPrice)
        case 'summary': return getFormattedPrice(cart.value?.taxedTotalPrice)
        case 'drawer' : return getFormattedPrice(cart.value?.taxedItemsPrice)
    }
})
</script>

<style lang="scss" scoped>
.sim-cart-total {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.sim-cart-total__total {
    display: flex;
    justify-content: space-between;

    @include sim-text-large;
}

.sim-cart-total__total--subtitle {
    @include sim-text-subtitle;
}
</style>
